@keyframes logo-slide-up {
  from {
    top: 30%;
  }
  to {
    top: 50px;
  }
}

@keyframes logo-max-width {
  from {
    max-width: 50rem;
  }
  to {
    max-width: 35rem;
  }
}

.logo-slide-up {
  animation: logo-slide-up 1.5s forwards;
}

/*
.logo-slide-up > img {
  animation: logo-max-width 2s forwards;
}
*/

@keyframes options-fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.options-fade-in {
  animation: options-fade-in 1.5s 0.6s forwards;
}

.options-fade-in-long {
  animation: options-fade-in 1.5s 1.5s forwards;
}

@keyframes bg-color-fade-in {
  from {
    background-color: inherit;
  }
  to {
    /* TODO Make this a passed value */
    /* #043650 */
    background-color: #043650;
  }
}

.bg-color-fade-in {
  animation: bg-color-fade-in 0.5s 0.5s forwards;
}

