:root {
  --bg-color: #043650;
  --blue: #043650;
  --green: #23ADB2;
  --white: #FFF;
  --orange: #ff8700;
  --light-blue: #0078FF;
  --red: #ff0000;
  background-color: var(--bg-color);
}

body {
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Work Sans', sans-serif;
  height: 100%;
  width: 100%;
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

img {
  width: 100%;
  height: auto;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
}
